import { css } from "@emotion/react";

import Text from "../Text";

const FilterMenu = ({
  children,
  label,
}: {
  children: JSX.Element;
  label: string;
}) => {
  return (
    <li
      css={css`
        margin-bottom: 8px;
        list-style: none;
      `}
    >
      <Text
        as={"h4"}
        css={css`
          margin-bottom: 12px;
          padding-top: 16px;
        `}
        size={"small"}
      >
        <Text as={"span"} size={"small"} weight={"semibold"}>
          {label}
        </Text>
      </Text>

      {children}
    </li>
  );
};

export default FilterMenu;
