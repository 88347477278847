import type {
  HeadingElement,
  TextColor,
  TextSize,
  TextWeight,
} from "@10x/types";
import { sizeLarge } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { CSSProperties, FunctionComponent, ReactNode } from "react";

import Text from "../Text";

export interface Props {
  /** Specifies the underlying heading element. */
  as: HeadingElement;

  children: ReactNode;

  className?: string;

  /** An enumerated color. */
  color?: TextColor;

  /**
   * Specifies whether the component should behave differently
   * on smaller screens.
   *
   * The media-aware behavior rules are straightforward:
   * if responsive is set to `true` then we inspect the
   * passed size and use the next size down. So for example,
   * if the passed size is `xlarge`, then we use `large`
   * instead on smaller screens. "Smaller" means screens
   * less than 900px wide, vaguely a tablet in horizontal
   * orientation.
   *
   * This behavior is opt-in. By default, the component renders
   * at the passed size regardless of media context.
   * */
  responsive?: boolean;

  /** An enumerated text size. */
  size: TextSize;

  /** A React style object to be applied inline. Use sparingly. */
  style?: CSSProperties;

  /** An enumerated font weight. */
  weight?: TextWeight;
}

/**
 * A typography primitive for headings. This builds on top
 * of the `Text` component to restrict the underlying element
 * to `h1` through `h6`. It also provides a consistent lower
 * margin, assumes `semibold` weight, and makes headings
 * easier to scan for in JSX.
 *
 * As with text generally, the semantic meaning of headings
 * is decoupled from their presentation.
 */
const Heading: FunctionComponent<Props> = ({
  as,
  children,
  className,
  color = "base",
  responsive = false,
  size,
  style,
  weight = "semibold",
}) => {
  return (
    <Text
      as={as}
      className={className}
      color={color}
      css={css`
        margin-bottom: ${sizeLarge};
      `}
      responsive={responsive}
      size={size}
      style={style}
      weight={weight}
    >
      {children}
    </Text>
  );
};

export default Heading;
