import type { Form_FullFragment } from "@10x/types/__generated__/graphql-types";
import { mediaPhoneOnly } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { Formik } from "formik";
import { useRouter } from "next/router";
import { stringify } from "qs";
import type { FunctionComponent } from "react";

import Button from "../../Button";
import Text from "../../Text";
import TextInput from "../../TextInput";

export enum Mode {
  dark = "dark",
  light = "light",
}

interface Props {
  data: Form_FullFragment;
  mode?: Mode;
  subscribeUrl: string;
}

const SubscribeForm: FunctionComponent<Props> = ({
  data,
  mode = Mode.dark,
  subscribeUrl,
}) => {
  const router = useRouter();
  const [{ description, submitLabel }] = data.steps || [];

  const buttonProps: {
    background?: "blue" | "red" | "transparent" | "white";
    border?: boolean;
  } = mode === Mode.light ? { background: "white", border: true } : {};
  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={async (values) => {
        const query = stringify({
          Email: values.email,
          redirectUrl: router.asPath,
        });
        router.push({
          pathname: subscribeUrl,
          query,
        });
      }}
    >
      {(props) => (
        <form
          css={css`
            display: flex;
            gap: 0.5rem;

            > input:first-of-type {
              flex: 2;
            }

            @media (max-width: ${mediaPhoneOnly}) {
              flex-direction: column;
              gap: 0;

              > * {
                margin: 0;
              }

              > input:first-of-type,
              > :last-of-type {
                flex: none;
              }

              > input:first-of-type {
                margin-bottom: 1rem;
              }

              > :last-of-type {
                width: 100%;
              }
            }
          `}
          onSubmit={props.handleSubmit}
        >
          <TextInput
            css={css`
              height: 40px;
            `}
            disabled={props.isSubmitting}
            id={"email"}
            name={"email"}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            placeholder={description || undefined}
            value={props.values.email || ""}
          />
          <Button
            type={"submit"}
            {...buttonProps}
            css={css`
              height: 40px;
              width: 120px;
              max-width: none;
            `}
          >
            <Text
              as={"span"}
              color={mode === Mode.light ? "blue" : "white"}
              css={css`
                pointer-events: none;
              `}
              size={"xsmall"}
              weight={"semibold"}
            >
              {submitLabel}
            </Text>
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default SubscribeForm;
