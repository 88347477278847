import searchClient from "@10x/algolia-utils/searchOnlyClient";
import {
  mediaTabletLandscape,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";
import { css, jsx } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";
import { Children, cloneElement, useState } from "react";
import type { SearchState } from "react-instantsearch-core";
import { InstantSearch } from "react-instantsearch-dom";

import { useUrlSync } from "./hooks";
import Query from "./Query";
import type { QueryInputProps } from "./QueryInput";
import Stats from "./Stats";

type HitsRenderer = (args: { onHits: () => void }) => ReactNode;
type ContentRenderer = (args: { onHits: () => void }) => ReactNode;
type Sidebar = JSX.Element;

type Props = (WithContentRenderer | WithHitsRenderer) &
  BaseProps &
  QueryInputProps;

interface WithHitsRenderer {
  contentRenderer?: never;
  hitsRenderer: HitsRenderer;
}

interface WithContentRenderer {
  contentRenderer: ContentRenderer;
  hitsRenderer?: never;
}

interface BaseProps {
  children?: ReactNode;
  /* A function to call on a search state change in order
   * to have external state management of the search
   */
  externalOnSearchStateChange?: (searchState: SearchState) => void;
  externalSearchState?: SearchState;
  /**
   * A function that will be passed to the clear filters
   * button as `transformItems`:
   * https://www.algolia.com/doc/api-reference/widgets/clear-refinements/react/#widget-param-transformitems
   *
   * Use this to filter attributes that should not be
   * cleared from refinements when the user hits the
   * "Clear all" refinements button.
   */

  includeStats?: boolean;

  indexName: string;

  isInfinite?: boolean;

  sidebar?: Sidebar;

  transformSearchState?: (searchState: SearchState) => SearchState;
}

const sidebarCss = css`
  width: 240px;
  margin-right: 5%;
  flex-shrink: 0;
  @media (max-width: ${mediaTabletLandscape}) {
    display: none;
  }
`;

/**
 * A full Algolia search experience, showing a query
 * input, a list of results, and filters in a sidebar.
 * For example usage, see EventSearch and VideoSearch.
 */
const Search: FunctionComponent<Props> = ({
  activeWidth,
  children,
  contentRenderer,
  hitsRenderer,
  inactiveWidth,
  includeStats,
  placeholder = "Search",
  searchIconAttrs,
  externalOnSearchStateChange,
  sidebar,
  externalSearchState,
  indexName,
  style,
  transformSearchState,
}) => {
  const { handleSearchStateChange, searchState } = useUrlSync();

  // Prevents sudden layout reflow on startup
  const [isVisible, setIsVisible] = useState(false);
  const handleHits = () => setIsVisible(true);

  // We need only one child to apply the sidebar class
  // to facilitate responsiveness
  const verifiedSidebar = sidebar && Children.only(sidebar);

  // https://github.com/emotion-js/emotion/pull/1985/files
  // https://github.com/emotion-js/emotion/issues/1102#issuecomment-446887725
  const sidebarWithCss =
    verifiedSidebar &&
    (verifiedSidebar.props.css
      ? cloneElement(verifiedSidebar, {
          css: [verifiedSidebar.props.css, sidebarCss],
        })
      : jsx(verifiedSidebar.type, {
          css: [verifiedSidebar.props.css, sidebarCss],
          ...verifiedSidebar.props,
        }));

  return (
    <InstantSearch
      indexName={indexName}
      onSearchStateChange={(searchState) => {
        externalOnSearchStateChange
          ? externalOnSearchStateChange(searchState)
          : handleSearchStateChange(searchState);
      }}
      searchClient={searchClient()}
      searchState={
        externalSearchState
          ? externalSearchState
          : transformSearchState
          ? transformSearchState(searchState)
          : searchState
      }
    >
      {children}
      {contentRenderer ? null : (
        <Query
          activeWidth={activeWidth}
          inactiveWidth={inactiveWidth}
          includeStats={includeStats}
          placeholder={placeholder}
          searchIconAttrs={searchIconAttrs}
          style={style}
        />
      )}

      <div
        css={css`
          display: ${isVisible ? "flex" : "none"};
          justify-content: space-between;

          @media (max-width: ${mediaTabletPortrait}) {
            flex-direction: column;
          }
        `}
      >
        {sidebar ? sidebarWithCss : null}

        <div
          css={css`
            width: 100%;
          `}
        >
          {includeStats && (
            <Stats
              css={css`
                display: block;
                margin-bottom: 1rem;
              `}
            />
          )}
          {contentRenderer ? (
            contentRenderer({ onHits: handleHits })
          ) : (
            <>{hitsRenderer!({ onHits: handleHits })}</>
          )}
        </div>
      </div>
    </InstantSearch>
  );
};

export default Search;
