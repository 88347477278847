import { colorSteelDarker, colorSteelLight } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { castArray, sortBy } from "lodash-es";
import type {
  MenuProvided,
  RefinementListProvided,
} from "react-instantsearch-core";

import type CheckBox from "../Form/CheckBox";
import Checkbox from "../Form/CheckBox";
import RadioButton from "../Form/RadioButton";
import RadioOrCheckBoxLabel from "../Form/RadioOrCheckboxLabel";
import type Switch from "../Switch";
import Text from "../Text";
import type { HierarchicalMenuProvided } from "./HierarchicalMenu";
import type { GenericItem } from "./types";

function isHierarchicalMenuItem(
  item: GenericItem,
): item is HierarchicalMenuProvided["items"][number] {
  return (
    (item as HierarchicalMenuProvided["items"][number]).items !== undefined
  );
}

export type Input = typeof RadioButton | typeof CheckBox | typeof Switch;

export type Props = {
  className?: string;
  input?: Input;
  items: GenericItem[];
  name?: string;
  transformItems?: (items: GenericItem[]) => GenericItem[];
};

const FacetList = ({
  className,
  input = Checkbox,
  items,
  name,
  refine,
  transformItems = (items) => items,
}: Props &
  Pick<
    RefinementListProvided | HierarchicalMenuProvided | MenuProvided,
    "refine"
  >) => {
  const Input = input;
  const transformedItems = transformItems(items);

  return (
    <ul
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 0;
        grid-gap: 8px;
      `}
    >
      {transformedItems.map((item) => {
        const handleChange = () => {
          refine(
            (input === RadioButton
              ? item.value
              : castArray(item.value)) as any[],
          );
        };

        const disabled = item.count === 0;
        return (
          <li
            css={css`
              &:empty {
                display: none;
              }
            `}
            key={String(item.value)}
          >
            <RadioOrCheckBoxLabel>
              <Input
                checked={item.isRefined}
                disabled={disabled}
                name={name}
                onChange={handleChange}
                value={item.value}
              />
              <Text
                as={"span"}
                css={css`
                  color: ${disabled ? colorSteelLight : colorSteelDarker};
                `}
                size={"small"}
              >
                {item.label}
              </Text>
            </RadioOrCheckBoxLabel>

            {isHierarchicalMenuItem(item) && item.items && (
              <FacetList
                css={css`
                  margin-left: 16px;
                `}
                input={Input}
                items={item.items}
                refine={refine}
                transformItems={(items) => sortBy(items, "label")}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default FacetList;
