import { useRouter } from "next/router";
import qs from "qs";
import type { RefObject } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import type { SearchState } from "react-instantsearch-core";
import { useIntersection } from "react-use";

import { InputStates } from "./constants";

export function useHitsFirstReceived(hits: unknown[], onHits: () => void) {
  const [notified, setNotified] = useState(false);
  useEffect(() => {
    if (!notified && hits.length) {
      onHits();
      setNotified(true);
    }
  }, [hits, notified, onHits]);
}

/**
 * A hook that manages infinite scrolling.
 */
export const useInfiniteScroll = (
  hasMore: boolean,
  refine: (value?: string) => void,
) => {
  const intersectionRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(intersectionRef, {});

  useEffect(() => {
    if (intersection?.isIntersecting && hasMore) {
      refine();
    }
    // refine changes too much, but we don't have control over it since it
    // comes from algolia
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersection, hasMore]);

  return { intersectionRef };
};

/**
 * A hook for managing the state of the QueryInput
 * in response to user interaction.
 */
export const useSearchBoxState = ({
  clearButtonRef,
  initialRefinement,
  inputRef,
  refine,
}: {
  clearButtonRef: RefObject<HTMLButtonElement>;
  initialRefinement?: string;
  inputRef: RefObject<HTMLInputElement>;
  refine: (value: string) => void;
}): {
  state: InputStates;
} => {
  const [currentState, setCurrentState] = useState(
    initialRefinement
      ? InputStates.ACTIVE_UNFOCUSED
      : InputStates.INACTIVE_UNFOCUSED,
  );

  /**
   * Clears the search and reverts the
   * SearchBox to inactive and unfocused.
   */
  const clearAll = useCallback((event: Event, current: HTMLElement) => {
    event.preventDefault();
    current?.blur();
    setCurrentState(InputStates.INACTIVE_UNFOCUSED);
    refine("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handles clear button interactions
  useEffect(() => {
    const { current }: any = clearButtonRef;

    const handleClick = (event: MouseEvent) => clearAll(event, current);
    current?.addEventListener("click", handleClick);

    return function cleanup() {
      current?.removeEventListener("click", handleClick);
    };
  }, [clearButtonRef, clearAll]);

  // Handles text input interactions
  useEffect(() => {
    const { current }: any = inputRef;

    /**
     * If the input contains a value,
     * set state to ACTIVE_UNFOCUSED.
     *
     * If the input is empty,
     * set state to INACTIVE_UNFOCUSED
     */
    const handleBlur = () => {
      current?.value
        ? setCurrentState(InputStates.ACTIVE_UNFOCUSED)
        : setCurrentState(InputStates.INACTIVE_UNFOCUSED);
    };

    /**
     * Whenever the input gains focus,
     * set state to ACTIVE_FOCUSED.
     */
    const handleFocus = () => setCurrentState(InputStates.ACTIVE_FOCUSED);

    /**
     * Set state from keyboard interactions.
     */
    const handleKeyDown = (event: KeyboardEvent) => {
      // Don't clear search on Enter; do nothing
      if (event.key === "Enter") {
        event.preventDefault();
      }

      // Clear search and go inactive on Escape
      if (event.key === "Escape") {
        clearAll(event, current);
      }
    };

    current?.addEventListener("blur", handleBlur);
    current?.addEventListener("focus", handleFocus);
    current?.addEventListener("keydown", handleKeyDown);

    return function cleanup() {
      current?.removeEventListener("blur", handleBlur);
      current?.removeEventListener("focus", handleFocus);
      current?.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputRef, clearAll]);

  return {
    state: currentState,
  };
};

/**
 * A hook for keeping search state in sync with the URL.
 */
export const useUrlSync = () => {
  const router = useRouter();

  // NextJS native parsing of query params doesn't support nested
  // hashes like `refinementList%5Bcategory.label%5D%5B0%5D=10x-pert%20Workshop&page=1`
  // so we have to grab it ourselves
  const searchString = router?.asPath.split("?")[1];
  const searchState = qs.parse(searchString);

  const handleSearchStateChange = (updatedSearchState: SearchState) => {
    router?.push(
      {
        pathname: router.asPath.split("?")[0],
        query: qs.stringify(updatedSearchState),
      },
      undefined,
      {
        scroll: false,
        shallow: true,
      },
    );
  };

  return {
    handleSearchStateChange,
    searchState,
  };
};
