import {
  colorSteelDarkest,
  colorSteelMedium,
  fontFamilyBase,
  fontLetterSpacingLarge,
  fontLetterSpacingMedium,
  fontSizeMedium,
  fontWeightRegular,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { HTMLProps } from "react";
import { forwardRef } from "react";

import { getSlashStyles } from "../Slash";

export type Props = Pick<
  HTMLProps<HTMLInputElement>,
  | "value"
  | "style"
  | "className"
  | "onChange"
  | "onFocus"
  | "placeholder"
  | "spellCheck"
>;

// https://github.com/yannickcr/eslint-plugin-react/issues/3015
// eslint-disable-next-line react/display-name
const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <>
      <input
        autoCapitalize={"off"}
        autoComplete={"off"}
        autoCorrect={"off"}
        className={props.className}
        css={css`
          ${getSlashStyles()}
          -moz-appearance: none;
          -webkit-appearance: none;
          background: none;
          border: none;
          box-sizing: border-box;
          color: ${colorSteelDarkest};
          flex: 1;
          font-family: ${fontFamilyBase};
          font-size: ${fontSizeMedium};
          font-weight: ${fontWeightRegular};
          height: 100%;
          letter-spacing: ${fontLetterSpacingLarge};
          outline: none;
          padding: 0 1rem 2px 1rem;

          [placeholder],
          ::placeholder {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          ::placeholder {
            color: ${colorSteelMedium};
            font-weight: ${fontWeightRegular};
            letter-spacing: ${fontLetterSpacingMedium};
          }

          ::-webkit-search-cancel-button {
            display: none;
          }
        `}
        id={"searchInput"}
        name={"Search"}
        ref={ref}
        spellCheck={false}
        type={"search"}
        {...props}
      />
    </>
  );
});

export default Input;
