import type { Navigation_FullFragment } from "@10x/types/__generated__/graphql-types";
import { colorSteelDarkest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Branding from "../Branding";
import Navigation from "../Navigation";

interface Props {
  backgroundColor?: string;
  className?: string;
  mode?: "light" | "dark";
  navigation: Navigation_FullFragment;
}

export const HEIGHT = "4rem";

const TopBar: FunctionComponent<Props> = ({
  backgroundColor = colorSteelDarkest,
  className,
  mode = "dark",
  navigation,
}) => {
  return (
    <div
      className={className}
      css={css`
        align-items: center;
        background: ${backgroundColor};
        box-sizing: border-box;
        display: flex;
        gap: 3.5rem;
        height: ${HEIGHT};
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 100;
      `}
    >
      <div
        css={css`
          transform: translateY(2px);
        `}
      >
        <Branding height={"2rem"} mode={mode} />
      </div>

      <Navigation data={navigation} mode={mode} />
    </div>
  );
};

export default TopBar;
